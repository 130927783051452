import { Chip, Stack } from "@mui/material";
import React from "react";
import { defineStyles } from "../../styles/defineStyles";

const Chips: React.FC<{
  chipArray: string[];
}> = (props) => {
  const styles = defineStyles({
    chips: {
      flexWrap: "wrap"
    },
    chip: {
      m: 0.5
    }
  });

  const chipClickHandler = () => {
    //donothing
  };

  return (
    <Stack direction="row" sx={styles.chips}>
      {props.chipArray.map((chip) => (
        <Chip
          label={chip}
          variant="outlined"
          color="primary"
          onClick={chipClickHandler}
          key={props.chipArray.indexOf(chip).toString()}
          sx={styles.chip}
        />
      ))}
    </Stack>
  );
};

export default Chips;
