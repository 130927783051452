import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import { Room } from "../models/settings";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import Unavailable from "../ui/Unavailable";
import RoomTabs from "./RoomTabs";

export const RoomsPage: React.FC = () => {
  const styles = defineStyles({
    tabs: {
      marginTop: "20px",
    },
  });

  const roomBreadCrumb: BreadCrumbsItem[] = [
    { name: "Místnosti", path: "/rooms" },
  ];
  const ctx = useContext(AuthContext);
  const isSelectedRoomHandler = (item: Room) => {
    return item;
  }
  
  return (
    <React.Fragment>
      {ctx.isLoggedAdmin && (
        <React.Fragment>
          <AppBarCustom
            isDetail={false}
            title="Místnosti"
            breadCrumb={roomBreadCrumb}
            addOrRemoveButtonCategory="room"
            pathToAdd={""}
            isSearchFunctionActive={false}
          />
          <Box sx={styles.tabs}>
            <RoomTabs isDetail={true} isSelected={isSelectedRoomHandler}/>
          </Box>
        </React.Fragment>
      )}
      {!ctx.isLoggedAdmin && <Unavailable />}
    </React.Fragment>
  );
};
