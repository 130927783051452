import { Notifications } from "@mui/icons-material";
import { TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import React, { useContext } from "react";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import { NotificationItem } from "../models/settings";
import NotificationAdmin from "./NotificationAdmin";
import NotificationUser from "./NotificationUser";

const NotificationTableRow: React.FC<{
  notification: NotificationItem;
}> = (props) => {
  const styles = defineStyles({
    timeCell: {
      width: "5%",
    },
    iconCell: {
      width: "10%",
    },
    notificationDescription: {
      width: "85%",
    },
  });

  const ctx = useContext(AuthContext);

  return (
    <TableRow>
      <TableCell sx={styles.iconCell}>
        <Notifications />
      </TableCell>
      <TableCell sx={styles.notificationDescription}>
        {ctx.isLoggedAdmin && <NotificationAdmin notification={props.notification} />}
        {!ctx.isLoggedAdmin && <NotificationUser notification={props.notification} />}
      </TableCell>
      <TableCell sx={styles.timeCell}>
        {dayjs(props.notification.createDateAndTime).format("HH:mm")}
      </TableCell>
    </TableRow>
  );
};

export default NotificationTableRow;
