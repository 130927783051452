import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import React, { useContext } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import { allUsersTemp, thirdCompany } from "../../App";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import { User, useRequiredValidation } from "../models/settings";
import Unavailable from "../ui/Unavailable";

const NewAdmin: React.FC = () => {
  const styles = defineStyles({
    main: {},
    comment: {
      mt: 2,
      width: "396px",
    },
    buttons: {
      mt: 2,
    },
  });

  const ctx = useContext(AuthContext);
  const navigate = useNavigate();
  const required = useRequiredValidation();

  const closedHandler = () => {
    navigate(-1);
  };

  const addNewAdminHandler = (data: { adminEmail: string }) => {
    const newAdmin = new User (true, "", "", data.adminEmail, "AA", "", "");
    allUsersTemp.push(newAdmin);
    navigate(-1);
  };

  return (
    <React.Fragment>
      {!ctx.isLoggedAdmin && <Unavailable />}
      {ctx.isLoggedAdmin && (
        <Dialog open={true} onClose={closedHandler} sx={styles.main}>
          <DialogTitle>Přidat správce</DialogTitle>
          <DialogContent>
            <FormContainer defaultValues={{}} onSuccess={addNewAdminHandler}>
              <TextFieldElement
                name="adminEmail"
                label="Email"
                id="adminEmail"
                validation={required}
                sx={styles.comment}
              />
              <Stack direction="row" sx={styles.buttons}>
                <div className="space"></div>
                <Button onClick={closedHandler} variant="text" color="inherit">
                  Zpět
                </Button>
                <Button type="submit" variant="text" color="primary">
                  Přidat
                </Button>
              </Stack>
            </FormContainer>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default NewAdmin;
