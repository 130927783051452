import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { allCompanies } from "../../App";
import {
  countAllEventsForUser,
  countAllRequestsForUser,
  findCompanyById,
  User,
} from "../models/settings";

const UserTableRow: React.FC<{
  user: User;
  link: string;
}> = (props) => {
  const linkToDetail = props.link + props.user.id;
  return (
    <TableRow>
      <TableCell>
        <Link to={linkToDetail} className="tableLink">
          {props.user.email}
        </Link>
      </TableCell>

      <TableCell>{props.user.email}</TableCell>
      <TableCell>
        {findCompanyById(allCompanies, props.user.companyId).description}
      </TableCell>
      <TableCell>{countAllEventsForUser(props.user.id)}</TableCell>
      <TableCell>{countAllRequestsForUser(props.user.id)}</TableCell>
    </TableRow>
  );
};

export default UserTableRow;
