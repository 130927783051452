import i18next from "i18next";
import React, { useContext, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";

import { AlertsBar } from "./components/AlertsBar";
import { Content } from "./components/Content";
import { AlertProvider } from "./components/contexts/AlertContext";
import AuthContext from "./components/contexts/AuthContext";
import { Menu } from "./components/menu/Menu";
import Navigation from "./components/Navigation";
import {
  Company,
  findCompanyByName,
  NotificationItem,
  RequestItem,
  Room,
  User,
} from "./components/models/settings";

import Login from "./components/login/Login";
import LoginSetPassword from "./components/login/LoginSetPassword";
import LoginActivation from "./components/login/LoginActivation";
import AccountVerification from "./components/login/AcountVerification";
import EmailVerification from "./components/login/EmailVerification";
import dayjs from "dayjs";

// Rooms -------------------------------------------------------------------------------
const prvni: Room = new Room(
  "prvni",
  "Prvni Zasedacka",
  "Zasedacka s capacitou 2-20 lidi je vhodna pro hybridni meetingy...",
  10,
  true,
  "/images/room1.jpg",
  ["Obrazovka", "SmartTabule", "Pojizdny bar"],
  "https://prvni.odkaz.na.kalendar.cz"
);
const druha: Room = new Room(
  "druha",
  "Druha Zasedacka",
  "Zasedacka s capacitou 2-20 lidi je vhodna pro hybridni meetingy...",
  50,
  true,
  "/images/room2.jpg",
  ["Obrazovka", "SmartTabule"],
  "https://...."
);

const treti: Room = new Room(
  "treti",
  "Treti Zasedacka",
  "Zasedacka s capacitou 2-20 lidi je vhodna pro hybridni meetingy...",
  8,
  false,
  "/images/room3.jpg",
  ["SmartTabule", "Pojizdny bar", "Koleckove brusle", "Kvetiny"],
  "https://treti.odkaz.na.kalendar.cz"
);

const ctvrta: Room = new Room(
  "ctvrta",
  "Ctvrta Zasedacka",
  "Zasedacka s capacitou 2-20 lidi je vhodna pro hybridni meetingy...",
  7,
  true,
  "/images/room4.jpg",
  ["Obrazovka", "Pojizdny bar"],
  "https://...."
);

export const allRoomsTemporary: Room[] = [prvni, druha, treti, ctvrta];

// Company ----------------------------------------------------------------------------------------

export const firstCompany: Company = new Company(
  "Hlavni statni spolecnost",
  "Pokusny text k prvni a hlavni statni spolecnosti"
);
export const secondCompany: Company = new Company(
  "Vedlejsi zabavni agentura",
  "Popis druhe vedlejsi spolecnosti"
);

export const thirdCompany: Company = new Company(
  "Admin",
  "Sprava systemu GreenTable"
);

export const allCompanies: Company[] = [firstCompany, secondCompany];

// clients -----------------------------------------------------------------------------------------

export const klient: User = new User(
  false,
  "Jan",
  "Novak",
  "jan.novak@gmail.com",
  "JN",
  firstCompany.id,
  "+420 123 456"
);
export const klient2: User = new User(
  false,
  "Martin",
  "Dlouhy",
  "Martin.Dlouhy@gmail.com",
  "MD",
  secondCompany.id,
  "+420 456 789"
);
export const admin: User = new User(
  true,
  "Tomas",
  "Marny",
  "tomas.marny@gmail.com",
  "TM",
  "",
  "+420 852 741"
);

export const allUsersTemp: User[] = [klient, klient2, admin];

// Requests ---------------------------------------------------------------------------------------

const prvniReq: RequestItem = new RequestItem(
  "prvni pozadavek",
  "Popis prvniho pozadavku",
  "prvni",
  findCompanyByName(allCompanies, "Hlavni statni spolecnost").id,
  15,
  false,
  false,
  true,
  dayjs("2022-01-01 8:00"),
  dayjs("2022-01-01 10:00"),
  klient.id,
  ""
);
const druhyReq: RequestItem = new RequestItem(
  "druhy pozadavek",
  "Popis druheho pozadavku trosku delsi",
  "ctvrta",
  findCompanyByName(allCompanies, "Vedlejsi zabavni agentura").id,
  18,
  true,
  true,
  false,
  dayjs("2022-01-02 10:00"),
  dayjs("2022-01-02 12:00"),
  klient2.id,
  ""
);
const tretiReq: RequestItem = new RequestItem(
  "treti pozadavek",
  "Popis tretiho pozadavku trosku delsi",
  "druha",
  findCompanyByName(allCompanies, "Hlavni statni spolecnost").id,
  11,
  true,
  false,
  false,
  dayjs("2022-01-03 18:00"),
  dayjs("2022-01-03 20:00"),
  klient.id,
  ""
);

//export const allRequestsTemp: RequestItem[] = [];
export const allRequestsTemp: RequestItem[] = [prvniReq, druhyReq, tretiReq];

// Notifications ---------------------------------------------------------------------------------
const prvniNotifikace: NotificationItem = new NotificationItem(prvniReq.id, dayjs().add(-1, "hour"), false );
const druhaNotifikace: NotificationItem = new NotificationItem(druhyReq.id, dayjs().add(-2, "hour"), false );
const tretiNotifikace: NotificationItem = new NotificationItem(tretiReq.id, dayjs().add(-3, "hour"), false );

export const allNotificationsTemp: NotificationItem[] = [prvniNotifikace, druhaNotifikace, tretiNotifikace];

function App() {
  const { t } = useTranslation();
  const notLoggedMessage = t(
    "notis.common.notLogged",
    "Pro práci v této části se musíte přihlásit"
  );
  const notAllowedMessage = t(
    "notis.common.notAllowed",
    "Váš profil neumožňuje práci v této části."
  );

  const ctx = useContext(AuthContext);

  return (
    <div>
      <HelmetProvider>
        <Helmet></Helmet>
        <AlertProvider>
          {!ctx.isLoggedIn && <Login />}
          {ctx.isLoggedIn && (
            <BrowserRouter key={i18next.language} basename="/">
              <Menu />
              <Navigation />
              <Content />
            </BrowserRouter>
          )}
          <AlertsBar />
        </AlertProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
