import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Company, countAllEventsForCompany, countAllRequestsForCompany } from "../models/settings";

const ClientsTableRow: React.FC<{
  company: Company;
  link: string;
}> = (props) => {
    const linkToDetail = props.link + props.company.id;
  return (
    <TableRow>
      <TableCell>
        <Link to={linkToDetail} className="tableLink">
          {props.company.name}
        </Link>
      </TableCell>

      <TableCell>{props.company.description}</TableCell>
      <TableCell>{countAllEventsForCompany(props.company.id)}</TableCell>
      <TableCell>{countAllRequestsForCompany(props.company.id)}</TableCell>
    </TableRow>
  );
};

export default ClientsTableRow;
