import React, { useState, useEffect } from "react";
import { User } from "../models/settings";

/* type AuthContextType = {
  isLoggedIn: Boolean;
  onLogout: () => {};
  onLogin: (email: String, password: String) => {};
}; */

const AuthContext = React.createContext({
  isLoggedIn: false,
  isLoggedAdmin: false,
  onLogout: () => {
    //donothing
  },
  onLogin: (user: User) => {
    //donothing
  },
});

export const AuthContextProvider: React.FC<{
  children?: React.ReactNode;
}> = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedAdmin, setIsLoggedAdmin] = useState(false); 
  const loginHandler = (user: User) => {
    localStorage.setItem("isLoggedIn", "1");
    localStorage.setItem("isLoggedAdmin", "0");
    setIsLoggedIn(true);
    if (user.isAdmin) {
      localStorage.setItem("isLoggedAdmin", "1");
      setIsLoggedAdmin(true);
    }
  };
  const logoutHandler = () => {
    localStorage.removeItem("isLoggedIn");
    setIsLoggedIn(false);
    setIsLoggedAdmin(false);
  };
  useEffect(() => {
    const storedUserLoggedInInformation = localStorage.getItem("isLoggedIn");
    const storedUserLoggedAdminInformation = localStorage.getItem("isLoggedAdmin");

    if (storedUserLoggedInInformation === "1") {
      setIsLoggedIn(true);
    }
    if (storedUserLoggedAdminInformation === "1") {
      setIsLoggedAdmin(true);
    }
  }, []);

  
   return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        isLoggedAdmin: isLoggedAdmin,
        onLogin: loginHandler,
        onLogout: logoutHandler
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
