import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { findRequestById, NotificationItem } from "../models/settings";

const NotificationUser: React.FC<{
    notification: NotificationItem
}> = (props) => {

    const path="/requests/";
    const goToHandler = () => {
        // TODO 
        console.log("Oznacit jako prectene")
    }

  return (
    <Link to={path} className="tableLink" onClick={goToHandler}>
      <Typography component="span" variant="body2">
        Vaše žádost{" "}
      </Typography>
      <Typography component="span" variant="body2" sx={{ fontWeight: "bold" }}>
        {findRequestById(props.notification.requestId).name}
      </Typography>
      {findRequestById(props.notification.requestId).isDecided && (
        <React.Fragment>
          <Typography component="span" variant="body2">
            {" "}
            byla
          </Typography>
          {findRequestById(props.notification.requestId).isAccepted && (
            <Typography component="span" variant="body2">
              {" "}
              schválená.
            </Typography>
          )}
          {!findRequestById(props.notification.requestId).isAccepted && (
            <Typography component="span" variant="body2">
              {" "}
              zamítnutá.
            </Typography>
          )}
        </React.Fragment>
      )}
      {!findRequestById(props.notification.requestId).isDecided && (
        <Typography component="span" variant="body2">
          {" "}
          nebyla rozhodnutá.
        </Typography>
      )}
    </Link>
  );
};

export default NotificationUser;
