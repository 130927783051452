import { Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { allRoomsTemporary } from "../../App";
import { defineStyles } from "../../styles/defineStyles";
import { findRequestById, findRoomByID, NotificationItem } from "../models/settings";

const NotificationAdmin: React.FC<{
    notification: NotificationItem
}> = (props) => {
  const styles = defineStyles({});

  const path="/requests/"+props.notification.requestId;
  const goToHandler = () => {
    //TODO nastavit jako prectene
    console.log("Notifikace nastavena jako prectena");
  }

  return (
    <Link to={path} className="tableLink" onClick={goToHandler}>
      <Typography component="span" variant="body2">
        Nová žádost{" "}
      </Typography>
      <Typography component="span" variant="body2" sx={{ fontWeight: "bold" }}>
        {findRequestById(props.notification.requestId).name}
      </Typography>
      <Typography component="span" variant="body2">
        {" "}
        na{" "}
      </Typography>
      <Typography component="span" variant="body2">
        {dayjs(
          findRequestById(props.notification.requestId).dateTimeFrom
        ).format("DD.MM.YYYY")}
      </Typography>
      <Typography component="span" variant="body2">
        {" "}
        v místnosti{" "}
      </Typography>
      <Typography component="span" variant="body2" sx={{ fontWeight: "bold" }}>
        {
          findRoomByID(
            allRoomsTemporary,
            findRequestById(props.notification.requestId).room
          ).title
        }
      </Typography>
    </Link>
  );
};

export default NotificationAdmin;
