import { Check, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { allCompanies, allRequestsTemp, allRoomsTemporary } from "../../App";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import { findCompanyById, findRoomByID, RequestItem } from "../models/settings";
import CustomDialog from "../ui/CustomDialog";

const RequestTableRow: React.FC<{
  request: RequestItem;
  link: string;
}> = (props) => {
  const styles = defineStyles({
    icon: {
      mr: 1,
      width: "16px",
      height: "18px",
    },
    button: {
      alignItems: "center",
    },
    buttonText: {
      fontSize: "13px",
    },
  });
  const ctx = useContext(AuthContext);
  const linkToDetail: string = props.link + props.request.id;
  const [isDecidedTemp, setIsDecidedTemp] = useState(props.request.isDecided);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const approveHandler = () => {
    console.log("Zadost byla schvalena");
    /* for (let i = 0; i < allRequestsTemp.length; i++) {
      let newRequest: RequestItem = allRequestsTemp[i];
      if (props.request.id === allRequestsTemp[i].id) {
        newRequest = { ...newRequest, isDecided: true, isAccepted: true };
        allRequestsTemp.splice(i, 1, newRequest);
        setIsDecidedTemp(true);
      }
    } */
  };
  const declineHandler = () => {
    setShowDialog(true);
    /* for (let i = 0; i < allRequestsTemp.length; i++) {
      let newRequest: RequestItem = allRequestsTemp[i];
      if (props.request.id === allRequestsTemp[i].id) {
        newRequest = { ...newRequest, isDecided: true, isAccepted: false };
        allRequestsTemp.splice(i, 1, newRequest);
        setIsDecidedTemp(true);
      }
    } */
  };

  const closeDialogHandler = () => {
    // zavreni dialogu(schovani)
    setShowDialog(false);
  };

  const requestDeniedHandler = (comment: string) => {
    // akce po stisknuti tlacitka v dialogu
    setShowDialog(false);

    // TODO nastaveni zadosti jako zamitnuta
    console.log("Nastaveni zadosti jako zamitnuta, duvod : " + comment);
  };

  const acceptedChip = (
    <Chip variant="filled" color="success" label="Potvrzená" />
  );
  const rejectedChip = (
    <Chip variant="filled" color="error" label="Zamítnutá" />
  );
  const undecidedChip = (
    <Chip variant="filled" color="primary" label="Čekání na potvrzení" />
  );
  const undecidedAdminComponent = (
    <Box>
      <Button
        variant="contained"
        color="success"
        sx={styles.button}
        size="small"
        onClick={approveHandler}
      >
        <Check sx={styles.icon} />
        <Typography sx={styles.buttonText}>Potvrdit</Typography>
      </Button>

      <Button
        variant="contained"
        color="error"
        sx={{ ml: 1 }}
        size="small"
        onClick={declineHandler}
      >
        <Close sx={styles.icon} />
        <Typography sx={styles.buttonText}>Zamítnout</Typography>
      </Button>
    </Box>
  );

  let statusChip = null;
  if (ctx.isLoggedAdmin) {
    if (!isDecidedTemp) statusChip = undecidedAdminComponent;
    if (isDecidedTemp && props.request.isAccepted)
      statusChip = acceptedChip;
    if (isDecidedTemp && !props.request.isAccepted)
      statusChip = rejectedChip;
  } else {
    if (!isDecidedTemp) statusChip = undecidedChip;
    if (isDecidedTemp && props.request.isAccepted)
      statusChip = acceptedChip;
    if (isDecidedTemp && !props.request.isAccepted)
      statusChip = rejectedChip;
  }

  return (
    <TableRow>
      <TableCell>
        {ctx.isLoggedAdmin && (
          <Link
            to={linkToDetail}
            className="tableLink"
          >
            {props.request.name}
          </Link>
        )}
        {!ctx.isLoggedAdmin && props.request.name}
      </TableCell>
      <TableCell>{props.request.description}</TableCell>
      <TableCell>
        {dayjs(props.request.dateTimeFrom).format("DD.MM.YYYY HH:mm")} -{" "}
        {dayjs(props.request.dateTimeTo).format("DD.MM.YYYY HH:mm")}
      </TableCell>
      <TableCell>
        {findRoomByID(allRoomsTemporary, props.request.room).title}
      </TableCell>
      <TableCell>
        {findCompanyById(allCompanies, props.request.companyId).name}
      </TableCell>
      <TableCell>{props.request.peopleCount}</TableCell>
      <TableCell>{statusChip}</TableCell>
      {showDialog && (
            <CustomDialog
              openDialog={showDialog}
              closed={closeDialogHandler}
              title="Zamítnutí žádosti"
              subtitle="Předtím než definitivně žádost zamítnete, udejte prosím důvod. Zvýšíte tak efektivitu komunikace s klientem."
              commentLabel="Důvod zamítnutí žádosti"
              commentPlaceholder="Nevhodný termín, zkuste prosím jiný."
              buttonText="Zamítnout žádost"
              onButtonClick={requestDeniedHandler}
            />
          )}
    </TableRow>
  );
};

export default RequestTableRow;
