import { Add, ArrowBack, Delete, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Stack,
  Typography,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Link,
  Breadcrumbs,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as LinkRouterDom, useNavigate } from "react-router-dom";
import { defineStyles } from "../../styles/defineStyles";

export type BreadCrumbsItem = {
  name: string;
  path: string;
};

const AppBarCustom: React.FC<{
  isDetail: boolean;
  title: string;
  breadCrumb: BreadCrumbsItem[];
  pathToAdd: string;
  addOrRemoveButtonCategory: string;
  isSearchFunctionActive: boolean;
  onRemoveFunction?: () => void;
}> = (props) => {
  const styles = defineStyles({
    stack: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    leftPart: {
      display: "flex",
      flexDirection: "column",
    },
    titleGroup: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    titleIcon: {
      pr: 1,
      color: "rgba(0, 0, 0, 0.87)",
    },
    title: {
      fontWeight: "500",
      fontSize: "20px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    subtitle: {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    button: {
      height: "36px",
      ml: 1,
      alignItems: "center",
    },
    search: {
      background: "rgba(0, 0, 0, 0.06)",
    },
    addText: {
      ml: 1,
    },
  });

  const navigate = useNavigate();
  const backHandler = () => {
    navigate(-1);
  };

  const { t } = useTranslation();
  const nameAkuzativ = "greentable."+props.addOrRemoveButtonCategory+".akuzativ";

  return (
    <Stack direction="row" sx={styles.stack}>
      <Box sx={styles.leftPart}>
        {props.isDetail && (
          <Link
            sx={styles.titleGroup}
            component="button"
            underline="none"
            onClick={backHandler}
          >
            <ArrowBack sx={styles.titleIcon} />{" "}
            <Typography sx={styles.title}>{props.title}</Typography>
          </Link>
        )}
        {!props.isDetail && (
          <Typography sx={styles.title}>{props.title}</Typography>
        )}
        <Breadcrumbs>
          {props.breadCrumb.map((item) => (
            <LinkRouterDom
              to={item.path}
              key={item.name}
              style={{
                textDecoration: "none",
                fontSize: "16px",
                color: "rgba(0, 0, 0, 0.54)",
              }}
            >
              {item.name}
            </LinkRouterDom>
          ))}
        </Breadcrumbs>
      </Box>
      <div className="space"></div>
      {props.isSearchFunctionActive && (
        <FormControl variant="outlined" size="small">
          <OutlinedInput
            id="searchInput"
            type="text"
            placeholder="Hledej ..."
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            sx={styles.search}
          />
        </FormControl>
      )}
      {props.pathToAdd.length>0 && (
        <LinkRouterDom to={props.pathToAdd} style={{textDecoration: "none"}}>
          <Button variant="contained" sx={styles.button}>
            <Add />
            <Typography sx={styles.addText}>Přidat {t(nameAkuzativ, nameAkuzativ)}</Typography>
          </Button>
        </LinkRouterDom>
      )}
      {props.onRemoveFunction != null && (
        <Button variant="contained" color="error" sx={styles.button} onClick={props.onRemoveFunction}>
        <Delete />
        <Typography sx={styles.addText}>Odstranit {t(nameAkuzativ)}</Typography>
      </Button>
      )}
    </Stack>
  );
};

export default AppBarCustom;
