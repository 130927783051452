import { Check, Close } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import DetailForm from "../ui/DetailForm";
import CustomDialog from "../ui/CustomDialog";

const EditRequest: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 888px)/2)",
      alignItem: "center",
      width: "600px",
    },
    icon: {
      mr: 1,
      width: "16px",
      height: "18px",
    },
    button: {
      ml: 2,
      alignItems: "center",
    },
    buttonText: {
      fontSize: "13px",
    },
  });

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const ctx = useContext(AuthContext);
  const navigate = useNavigate();
  const path = "/requests/";

  const editRequestBreadcrumb: BreadCrumbsItem[] = [
    { name: "Žádosti", path: "/requests" },
    { name: "Detail žádosti", path: "" },
  ];

  const acceptRequestHandler = () => {
    // TODO nastaveni requestu jako "potvrzena"
    console.log("Nastaveni zadosti jako schvalena.");
    navigate(path);
  };

  const deneidRequestHandler = () => {
    // otevreni dialogoveho okna s komentarem k zamitnuti
    setShowDialog(true);
  };

  const closeDialogHandler = () => {
    // zavreni dialogu(schovani)
    setShowDialog(false);
  };

  const requestDeniedHandler = (comment: string) => {
    // akce po stisknuti tlacitka v dialogu
    setShowDialog(false);

    // TODO nastaveni zadosti jako zamitnuta
    console.log("Nastaveni zadosti jako zamitnuta, duvod : " + comment);
    navigate(path);
  };

  const onSubmitActionHandler = () => {
    // funkce pro ulozeni formulare, v tomto pripade se neuklada
    //donothing
  };

  return (
    <React.Fragment>
      {ctx.isLoggedAdmin && (
        <React.Fragment>
          <AppBarCustom
            isDetail={false}
            title="Detail žádosti"
            breadCrumb={editRequestBreadcrumb}
            addOrRemoveButtonCategory="request"
            pathToAdd={""}
            isSearchFunctionActive={false}
          />
          <Box sx={styles.main}>
            <DetailForm
              isSubmitAllowed={false}
              onSubmitAction={onSubmitActionHandler}
            />
            <Stack direction="row" sx={{ mt: 3 }}>
              <div className="space"></div>
              <Button
                variant="contained"
                sx={styles.button}
                color="success"
                onClick={acceptRequestHandler}
              >
                <Check sx={styles.icon} />
                <Typography sx={styles.buttonText}>Potvrdit</Typography>
              </Button>
              <Button
                variant="contained"
                sx={styles.button}
                color="error"
                onClick={deneidRequestHandler}
              >
                <Close sx={styles.icon} />
                <Typography sx={styles.buttonText}>Zamítnout</Typography>
              </Button>
            </Stack>
          </Box>
          {showDialog && (
            <CustomDialog
              openDialog={showDialog}
              closed={closeDialogHandler}
              title="Zamítnutí žádosti"
              subtitle="Předtím než definitivně žádost zamítnete, udejte prosím důvod. Zvýšíte tak efektivitu komunikace s klientem."
              commentLabel="Důvod zamítnutí žádosti"
              commentPlaceholder="Nevhodný termín, zkuste prosím jiný."
              buttonText="Zamítnout žádost"
              onButtonClick={requestDeniedHandler}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default EditRequest;
