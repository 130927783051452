import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { FormContainer, PasswordElement, TextFieldElement } from "react-hook-form-mui";
import { klient } from "../../App";
import { defineStyles } from "../../styles/defineStyles";
import { User, useRequiredValidation } from "../models/settings";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";

const UserSettings: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 788px)/2)",
      alignItem: "center",
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
  });

  const buttonText = "Uložit změny";

  const userSettingsBreadCrumb: BreadCrumbsItem[] = [
    { name: "Nastavení", path: "/settings" },
  ];

  const required = useRequiredValidation();

  const changeUserPhoneHandler = (data: { userPhone: string }) => {
    const newClient: User = { ...klient, phone: data.userPhone };
    console.log("User data " + JSON.stringify(newClient));
  };

  const changePasswordHandler = (data: {
    oldPassword: string;
    newPassword: string;
  }) => {
    console.log(
      "Puvodni heslo: " + data.oldPassword + "  / Nove heslo: " + data.newPassword
    );
  };

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={false}
        title="Nastavení účtu"
        breadCrumb={userSettingsBreadCrumb}
        pathToAdd={""}
        addOrRemoveButtonCategory="settings"
        isSearchFunctionActive={false}
      />
      <Box sx={styles.main}>
        <FormContainer
          defaultValues={{ userPhone: klient.phone }}
          onSuccess={changeUserPhoneHandler}
        >
          <Typography variant="h5">Základní informace</Typography>
          <TextFieldElement
            name="userPhone"
            id="userPhone"
            label="Telefon"
            validation={required}
            sx={styles.formItem}
          />
          <Stack direction="row" sx={styles.formItem}>
            <div className="space"></div>
            <Button type="submit" variant="contained">
              {buttonText}
            </Button>
          </Stack>
        </FormContainer>
        <Typography variant="h5" sx={styles.formItem}>
          Změnit heslo
        </Typography>
        <Typography variant="body2" sx={styles.formItem}>
          Vytvořte unikátní heslo, které nelze prakticky uhodnout.
        </Typography>
        <FormContainer defaultValues={{}} onSuccess={changePasswordHandler}>
          <PasswordElement
            name="oldPassword"
            label="Původní heslo"
            validation={required}
            sx={styles.formItem}
          />
          <PasswordElement
            name="newPassword"
            label="Nové heslo"
            validation={required}
            sx={styles.formItem}
          />
          <Stack direction="row" sx={styles.formItem}>
            <div className="space"></div>
            <Button type="submit" variant="contained">
              {buttonText}
            </Button>
          </Stack>
        </FormContainer>
      </Box>
    </React.Fragment>
  );
};

export default UserSettings;
