import { Box } from "@mui/material";
import React from "react";
import { defineStyles } from "../../styles/defineStyles";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import UserTable from "./UserTable";

const UserPage: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 3,
    },
  });
  const userBreadCrumb: BreadCrumbsItem[] = [{ name: "", path: "" }];

  return (
    <Box sx={styles.main}>
      <AppBarCustom
        isDetail={false}
        title="Účty"
        breadCrumb={userBreadCrumb}
        addOrRemoveButtonCategory="user"
        pathToAdd="/clients/user/new"
        isSearchFunctionActive={true}
      />
      <UserTable />
    </Box>
  );
};

export default UserPage;
