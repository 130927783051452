import { CalendarToday } from "@mui/icons-material";
import { Link, Typography } from "@mui/material";
import React from "react";
import { defineStyles } from "../../styles/defineStyles";

const CalendarLink: React.FC<{
  link: string;
}> = (props) => {
  const styles = defineStyles({
    link: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      textDecoration: "none",
      alignItems: "center",
    },
    icon: {
      p: 0,
      pr: 1,
    },
    text: {

    }
  });

  return (
    <Link href={props.link} sx={styles.link}>
      <CalendarToday sx={styles.icon} />
      <Typography sx={styles.text}>Kalendář</Typography>
    </Link>
  );
};

export default CalendarLink;
