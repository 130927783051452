import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { defineStyles } from "../../styles/defineStyles";
import {
  RequestItem,
} from "../models/settings";
import RequestTableRow from "./RequestTableRow";

const RequestTable: React.FC<{
  requests: RequestItem[];
}> = (props) => {
  const styles = defineStyles({
    table: {},
    head: {
      fontWeight: "bold",
    },
  });

  const changePageHandler = () => {
    //donothing
  };
  const changeRowsPerPageHandler = () => {
    //donothing
  };

  return (
    <React.Fragment>
      {props.requests.length == 0 && (
        <Typography sx={{ mt: 2 }}>Nenalezli jsme žádnou žádost</Typography>
      )}
      {props.requests.length > 0 && (
        <TableContainer component={Box} id="tableContainer">
          <Table sx={styles.table}>
            <TableHead>
              <TableRow key="headerRow">
                <TableCell sx={styles.head}>Název žádosti</TableCell>
                <TableCell sx={styles.head}>Popis</TableCell>
                <TableCell sx={styles.head}>Termín</TableCell>
                <TableCell sx={styles.head}>Místnost</TableCell>
                <TableCell sx={styles.head}>Klient</TableCell>
                <TableCell sx={styles.head}>Počet lidí</TableCell>
                <TableCell sx={styles.head}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.requests.map((request) => (
                <RequestTableRow
                  request={request}
                  key={request.id}
                  link="/requests/"
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={1}
            rowsPerPage={5}
            page={0}
            onPageChange={changePageHandler}
            onRowsPerPageChange={changeRowsPerPageHandler}
          />
        </TableContainer>
      )}
    </React.Fragment>
  );
};

export default RequestTable;
