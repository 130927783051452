import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { defineStyle, defineStyles } from "../../styles/defineStyles";

type MenuItemProps = {
  icon: React.ReactNode;
  text: string;
  route: string;
};

const rootStyle = defineStyle({
  color: "rgba(0, 0, 0, 0.54)",
  mb: 0.25,
});

const styles = defineStyles({
  active: {
    color: "rgba(25, 118, 210, 1)",
  },
  inactive: rootStyle,
  textActive: { color: "rgba(25, 118, 210, 1)" },
  textInactive: {
    color: "rgba(0,0,0,0.87)",
  },
});

export const MenuItem: React.FC<MenuItemProps> = ({ text, route, icon }) => {
  const location = useLocation().pathname;
  const isActive = location.startsWith(route);

  return (
    <ListItem component={NavLink} to={route} disablePadding sx={rootStyle}>
      <ListItemButton>
        <ListItemIcon sx={isActive ? styles.active : styles.inactive}>
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={text}
          className="menuItem"
          disableTypography
          sx={isActive ? styles.textActive : styles.textInactive}
        />
      </ListItemButton>
    </ListItem>
  );
};
