import { CancelRounded, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { allUsersTemp } from "../../App";
import { defineStyles } from "../../styles/defineStyles";
import { findAllAdmins, User } from "../models/settings";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import AdminSettingsTableRow from "./AdminSettingTableRow";

const AdminSettings: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 788px)/2)",
      alignItem: "center",
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
    tableBox: {
      mt: 3,
      width: "100%",
    },
    mainColumn: {
      fontWeight: "bold",
      width: "80%",
    },
    actionColumn: {
      fontWeight: "bold",
      width: "10%",
    },
    iconButton: {
      mr: 1,
    },
  });

  const adminSettingsBreadCrumb: BreadCrumbsItem[] = [
    { name: "Nastavení", path: "/settings" },
  ];

  const [allAdmins, setAllAdmins] = useState<User[]>([]);

  useEffect(() => {
    setAllAdmins(findAllAdmins());
  }, [allUsersTemp]);

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={false}
        title="Nastavení"
        breadCrumb={adminSettingsBreadCrumb}
        pathToAdd="/settings/new"
        isSearchFunctionActive={false}
        addOrRemoveButtonCategory="settings.admin"
      />
      <Box sx={styles.tableBox}>
        <Typography variant="h5">Správci systému</Typography>
        <Table sx={styles.formItem}>
          <TableHead>
            <TableCell sx={styles.mainColumn}>Uživatel</TableCell>
            <TableCell sx={styles.actionColumn}>Akce</TableCell>
          </TableHead>
          <TableBody>
            {allAdmins.map((admin) => (
              <AdminSettingsTableRow admin={admin} key={admin.id} />
            ))}
          </TableBody>
        </Table>
      </Box>
    </React.Fragment>
  );
};

export default AdminSettings;
