import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { allUsersTemp } from "../../App";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import { findAllUsersForCompany } from "../models/settings";
import UserTableRow from "./UserTableRow";

const UserTable: React.FC = () => {
  const styles = defineStyles({
    table: {
      mt: 3,
    },
    head: {
      fontWeight: "bold",
      width: "100%",
    },
    headName: {
      fontWeight: "bold",
      width: "20%",
    },
    headDescription: {
      fontWeight: "bold",
      width: "20%",
    },
    headItem: {
      fontWeight: "bold",
      width: "10%",
    },
  });

  const data = useParams();
  const companyId = data.id;

  const changePageHandler = () => {
    //donothing
  };
  const changeRowsPerPageHandler = () => {
    //donothing
  };
  const ctx = useContext(AuthContext);

  const rateRequestHandler = (value: boolean) => {
    //donothing
  };
  return (
    <React.Fragment>
      {findAllUsersForCompany(companyId).length == 0 && (
        <Typography sx={{ mt: 2 }}>Nenalezli jsme žádného uživatele</Typography>
      )}
      {findAllUsersForCompany(companyId).length > 0 && (
        <TableContainer component={Box} id="tableContainer">
          <Table sx={styles.table}>
            <TableHead>
              <TableRow key="headerRow">
                <TableCell sx={styles.headName}>Název účtu</TableCell>
                <TableCell sx={styles.headDescription}>Kontakt</TableCell>
                <TableCell sx={styles.headDescription}>Status</TableCell>
                <TableCell sx={styles.headItem}>Počet událostí</TableCell>
                <TableCell sx={styles.headItem}>Počet žádostí</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {findAllUsersForCompany(companyId).map((user) => (
                <UserTableRow key={user.id} user={user} link="/clients/user/" />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={1}
            rowsPerPage={5}
            page={0}
            onPageChange={changePageHandler}
            onRowsPerPageChange={changeRowsPerPageHandler}
          />
        </TableContainer>
      )}
    </React.Fragment>
  );
};

export default UserTable;
