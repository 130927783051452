import { Box, Stack, TextField } from "@mui/material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  MobileTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { defineStyles } from "../../styles/defineStyles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AccessTime } from "@mui/icons-material";
import { DateTimePickerElement, FormContainer } from "react-hook-form-mui";

const DatePickerComp: React.FC<{
  dateFrom: Dayjs,
  dateTo: Dayjs,
  selectedDateInterval: (dateTimeInterval:Dayjs[]) => void
}> = (props) => {
  const styles = defineStyles({
    datePicker: {
      borderRadius: "4px",
      width: "500px",
      height: "45px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    picker: {
      flexDirection: "reverse-row",
    },
  });

  // pokus o vlastni dateTime picker from to

  /* const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs("2022-10-10"));
  const [timeFrom, setTimeFrom] = useState<Dayjs | null>(dayjs("09:00"));
  const [timeTo, setTimeTo] = useState<Dayjs | null>(dayjs("12:00")); */

  const dateTimeNow = dayjs();

  const [dateTimeFrom, setDateTimeFrom] = useState<Dayjs | null>(
    props.dateFrom
  );
  const [dateTimeTo, setDateTimeTo] = useState<Dayjs | null>(
    props.dateTo
    //dateTimeNow.add(2, "hours")
  );

  useEffect(()=>{
    props.selectedDateInterval([dateTimeFrom,dateTimeTo]);
  },[dateTimeFrom, dateTimeTo]);

  return (
    <Box sx={styles.datePicker}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <DatePicker
          label=""
          inputFormat="DD.MM.YYYY"
          value={dateValue}
          onChange={(newValue) => {
            setDateValue(newValue);
          }}
          components={{ OpenPickerIcon: AccessTime }}
          renderInput={(params) => <TextField variant="standard" {...params} />}
          InputAdornmentProps={{ position: "start" }}
        />
        <TimePicker
          label=""
          value={timeFrom}
          onChange={(newValue) => {
            setTimeFrom(newValue);
          }}
          renderInput={(params) => <TextField variant="standard" {...params} />}
        />
        <TimePicker
          label=""
          value={timeTo}
          onChange={(newValue) => {
            setTimeTo(newValue);
          }}
          renderInput={(params) => <TextField variant="standard" {...params} />}
        /> */}

        <Stack direction="row">
          <DateTimePicker
            label="Termín od"
            value={dateTimeFrom}
            onChange={(newValue) => {
              setDateTimeFrom(newValue);
            }}
            renderInput={(params) => (
              <TextField variant="standard" {...params} />
            )}
            InputAdornmentProps={{ position: "start" }}
            components={{ OpenPickerIcon: AccessTime }}
          />
          <DateTimePicker
            label="Termín do"
            value={dateTimeTo}
            onChange={(newValue) => {
              setDateTimeTo(newValue);
            }}
            renderInput={(params) => (
              <TextField variant="standard" {...params} sx={{ ml: 3}}/>
            )}
            InputAdornmentProps={{ position: "start" }}
            components={{ OpenPickerIcon: AccessTime }}
          />
        </Stack>
      </LocalizationProvider>
    </Box>
  );
};

export default DatePickerComp;
