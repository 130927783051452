import { Box } from "@mui/material";
import React, { useContext } from "react";
import { klient } from "../../App";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import {
  findRequestsForAdmin,
  findRequestsForUser,
  RequestItem,
} from "../models/settings";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import EmptyRequestContent from "./EmptyRequestContent";
import RequestTable from "./RequestTable";

const RequestPage: React.FC = () => {
  const styles = defineStyles({
    tabs: {
      marginTop: "20px",
    },
  });

  const roomBreadCrumb: BreadCrumbsItem[] = [
    { name: "Žádosti", path: "/requests" },
  ];
  const ctx = useContext(AuthContext);

  let allRequestsSelected: RequestItem[] = [];
  if (ctx.isLoggedAdmin) {
    allRequestsSelected = findRequestsForAdmin();
  } else {
    allRequestsSelected = findRequestsForUser(klient);
  }

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={false}
        title="Žádosti"
        breadCrumb={roomBreadCrumb}
        addOrRemoveButtonCategory="request"
        pathToAdd={"/requests/new"}
        isSearchFunctionActive={false}
      />
      <Box sx={styles.tabs}>
        {allRequestsSelected.length == 0 && <EmptyRequestContent />}
        {allRequestsSelected.length > 0 && (
          <RequestTable requests={allRequestsSelected} />
        )}
      </Box>
    </React.Fragment>
  );
};

export default RequestPage;
