import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";
import "./i18n";
import {AuthContextProvider} from "./components/contexts/AuthContext";
import {buildApiClient} from "./api/buildApiClient";

/* // DEMO - SMAZAT AZ NASTUDUJES
console.log("Starting app, apiRoot=", process.env.REACT_APP_API_ROOT);

const apic = buildApiClient(process.env.REACT_APP_API_ROOT, null);
const echoRequest = "Hello "+new Date().toISOString();

console.log("Echo request:", echoRequest)
apic.echo( {value: echoRequest}).then((d) => {
  console.log("Echo response", d.value);
}); */

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </React.StrictMode>
);
