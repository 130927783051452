import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CardActions,
  Link,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useContext } from "react";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import "../../index.css";
import { admin, klient } from "../../App";

const Login = () => {
  const ctx = useContext(AuthContext);
  const style = defineStyles({
    all: {
      width: "100vw",
      height: "100vh",
      position: "relative",
    },
    card: {
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "350px",
      height: "400px",
      transform: "translate(-50%,-50%)",
      p: 2,
    },
    formGroup: {},
    textField: {
      mb: 2,
    },
    button: {
      width: "100%",
      mb: 2,
    },
    link: {
      pl: 2,
    },
  });

  const [values, setValues] = React.useState<{
    password: string;
    showPassword: boolean;
  }>({
    password: "",
    showPassword: false,
  });

  const handleChange =
    (prop: keyof { password: string; showPassword: boolean }) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const loginHandler = () => {
    ctx.onLogin(klient);
  }

  const googleLoginHandler = () => {
    ctx.onLogin(admin);
  }

  return (
    <Box sx={style.all}>
      <Card sx={style.card} elevation={3}>
        <CardHeader
          title="Přihlášení"
          subheader="Začněte rezervovat místnosti v GreenTable."
        />
        <CardContent>
          {/*TODO: tady to chce pouzit ty Elementy z react-hook-form-mui, napriklad to heslo s ikonkou maji uz vyresene */}
          <FormGroup sx={style.formGroup}>
            <TextField required id="email" label="Email" sx={style.textField} />
            <FormControl sx={style.textField} variant="outlined">
              <InputLabel htmlFor="password">Heslo</InputLabel>
              <OutlinedInput
                required
                id="password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </FormGroup>
          <Button variant="contained" sx={style.button} onClick={loginHandler}>
            Přihlášení
          </Button>
          <Button variant="outlined" sx={style.button} onClick={googleLoginHandler}>
            Přihlášení Google
          </Button>
        </CardContent>
        <CardActions>
          <Link href="#" underline="hover" sx={style.link}>
            Zapomenute heslo
          </Link>
        </CardActions>
      </Card>
    </Box>
  );
};

export default Login;
