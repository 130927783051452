import {
  MoreHorizRounded,
  Notifications,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  IconButton,
  Stack,
  Tooltip,
  Menu,
  MenuItem,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { defineStyles } from "../styles/defineStyles";
import AuthContext from "./contexts/AuthContext";

import "../index.css";
import { Link } from "react-router-dom";
import {
  admin,
  allCompanies,
  allNotificationsTemp,
  allRequestsTemp,
  klient,
} from "../App";
import {
  findCompanyById,
  findNotificationsForAdmin,
  findNotificationsForUser,
  NotificationItem,
  User,
} from "./models/settings";
import NotificationAdmin from "./notification/NotificationAdmin";
import NotificationUser from "./notification/NotificationUser";

const Navigation = () => {
  const ctx = useContext(AuthContext);
  const style = defineStyles({
    menu: {
      width: "calc(100% - 64px)",
      height: "64px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      boxShadow:
        "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
      position: "fixed",
      left: "64px",
      top: "0",
      zIndex: "90",
      backgroundColor: "white",
      marginBottom: "15px",
    },
    icon: {
      width: "48px",
      height: "48px",
      mr: 1,
    },
    loginName: {
      pl: 3,
      fontSize: "20px",
      fontWeight: "500",
      color: "rgba(0, 0, 0, 0.6)",
    },
    avatar: {
      width: "40px",
      height: "40px",
      marginRight: "48px",
    },
    menuItemText: {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    menuItemSubtext: {
      fontSize: "14px",
      color: "rgba(0, 0, 0, 0.6)",
    },
    iconNotification: {
      width: "24px",
      height: "24px",
      mr: 4,
    },
    bold: {
      fontWeigth: "bold",
    },
    notificationRow: {
      alignItems: "center",
    },
  });

  const [allUserNotifications, setAllUserNotifications] = useState<
    NotificationItem[]
  >([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElTwo, setAnchorElTwo] = React.useState<null | HTMLElement>(
    null
  );
  const openUser = Boolean(anchorEl);
  const openNotification = Boolean(anchorElTwo);
  const handleClickUser = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUser = () => {
    setAnchorEl(null);
  };
  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTwo(event.currentTarget);
  };
  const handleCloseNotification = () => {
    setAnchorElTwo(null);
  };

  let user: User;
  let showedName = "";
  if (ctx.isLoggedAdmin) {
    user = admin;
    showedName = "Admin";
  } else {
    user = klient;
    showedName = findCompanyById(allCompanies, user.companyId).name;
  }

  useEffect(() => {
    if (ctx.isLoggedAdmin) {
      setAllUserNotifications(allNotificationsTemp);
      // setAllUserNotifications(findNotificationsForAdmin()); TODO nefacha, spatne funkce pro ukazku plnim vsemi.
    } else {
      setAllUserNotifications(allNotificationsTemp);
      // setAllUserNotifications(findNotificationsForUser(klient.id));
    }
  }, [ctx, allRequestsTemp]);

  return (
    <Stack direction="row" sx={style.menu}>
      <Typography sx={style.loginName}>{showedName}</Typography>
      <div className="space"></div>
      <Tooltip title="Notifikace" sx={style.icon}>
        <IconButton
          onClick={handleClickNotification}
          aria-controls={open ? "notification-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Badge
            badgeContent={allUserNotifications.length}
            color="secondary"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Notifications />
          </Badge>
        </IconButton>
      </Tooltip>
      <Tooltip title="Nastavení účtu" sx={style.avatar}>
        <IconButton
          onClick={handleClickUser}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar>{user.avatar}</Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openUser}
        onClose={handleCloseUser}
        onClick={handleCloseUser}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Link to="/settings" className="navigationMenuItem">
            <Box>
              <Typography sx={style.menuItemText}>
                {user.firstName} {user.lastName}
              </Typography>
              <Typography sx={style.menuItemSubtext}>{user.email}</Typography>
            </Box>
          </Link>
        </MenuItem>
        <MenuItem onClick={ctx.onLogout}>
          <Typography sx={style.menuItemText}>Odhlasit</Typography>
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorElTwo}
        id="notification-menu"
        open={openNotification}
        onClose={handleCloseNotification}
        onClick={handleCloseNotification}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: "400px",
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {allUserNotifications.map((notification) => (
          <Box key={notification.id}>
            <MenuItem>
              {/* <Link
                to="/notifications"
                style={{ textDecoration: "none", color: "inherit" }}
              > */}
                <Stack direction="row" sx={style.notificationRow}>
                  <Notifications sx={style.iconNotification} />
                  {ctx.isLoggedAdmin && <NotificationAdmin notification={notification} />}
                  {!ctx.isLoggedAdmin && <NotificationUser notification={notification} />}
                </Stack>
              {/* </Link> */}
            </MenuItem>
            <Divider />
          </Box>
        ))}
        <MenuItem>
          <Link to="/notifications" className="navigationMenuItem">
            <Stack direction="row">
              <MoreHorizRounded sx={style.iconNotification} />
              <Typography variant="body2">
                Zobrazit všechny notifikace
              </Typography>
            </Stack>
          </Link>
        </MenuItem>
        <Divider />
      </Menu>
    </Stack>
  );
};

export default Navigation;
