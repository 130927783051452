import { Box } from "@mui/material";
import React from "react";
import { defineStyles } from "../../styles/defineStyles";
import { Room } from "../models/settings";
import RoomCard from "./RoomCard";

const RoomList: React.FC <{
    allRooms: Room[];
    isDetail: boolean;
    isSelected: (item: Room) => void;
}> = (props) => {
    const styles = defineStyles({
        rooms: {
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          p: 0,
        },
      });

    return (
        <Box sx={styles.rooms}>
          {props.allRooms.map((item) => (
            <RoomCard room={item} key={Math.random()} isDetail={props.isDetail} isSelected={props.isSelected}/>
          ))}
        </Box>
    );
}

export default RoomList;