import { Box } from "@mui/material";
import React, { useContext } from "react";
import { defineStyles } from "../../styles/defineStyles";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import RoomDetailItem from "./RoomDetailItem";
import { useParams } from "react-router-dom";
import { findRoomByID } from "../models/settings";
import { allRoomsTemporary } from "../../App";
import AuthContext from "../contexts/AuthContext";
import Unavailable from "../ui/Unavailable";

const RoomDetail: React.FC = () => {
  const styles = defineStyles({
    main: {
      display: "flex",
      flexDirection: "column",
    },
  });

  const ctx = useContext(AuthContext);

  const roomIdObj = useParams();
  const room = findRoomByID(allRoomsTemporary, roomIdObj.id);
  const roomDetailBreadCrumb: BreadCrumbsItem[] = [
    { name: "Místnosti", path: "/rooms" },
    { name: "Detail místnosti", path: "" },
  ];

  return (
    <React.Fragment>
      {ctx.isLoggedAdmin && (
        <Box sx={styles.main}>
          <AppBarCustom
            isDetail={true}
            title={room.title}
            breadCrumb={roomDetailBreadCrumb}
            addOrRemoveButtonCategory="room"
            pathToAdd={""}
            isSearchFunctionActive={false}
          />
          <RoomDetailItem room={room} />
        </Box>
      )}
      {!ctx.isLoggedAdmin && <Unavailable />}
    </React.Fragment>
  );
};

export default RoomDetail;
