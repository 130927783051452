import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { allNotificationsTemp } from "../../App";
import { defineStyles } from "../../styles/defineStyles";
import { NotificationItem, RequestItem } from "../models/settings";
import NotificationTableRow from "./NotificationTableRow";

const NotificationTable: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 888px)/2)",
      alignItem: "center",
      width: "600px",
    },
    table: {},
  });

  const changePageHandler = () => {
    //donothing
  };
  const changeRowsPerPageHandler = () => {
    //donothing
  };

  const [allUserNotifications, setAllNotifications] =
    useState<NotificationItem[]>(allNotificationsTemp);

  return (
    <Box sx={styles.main}>
      {allUserNotifications.length == 0 && (
        <Typography sx={{ mt: 2 }}>
          Nenalezli jsme žádnou notifikaci.
        </Typography>
      )}
      {allUserNotifications.length > 0 && (
        <TableContainer component={Box} id="tableContainer">
          <Table sx={styles.table}>
            <TableBody>
              {allUserNotifications.map((notification) => (
                <NotificationTableRow
                  notification={notification}
                  key={notification.id}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={1}
            rowsPerPage={5}
            page={0}
            onPageChange={changePageHandler}
            onRowsPerPageChange={changeRowsPerPageHandler}
          />
        </TableContainer>
      )}
    </Box>
  );
};

export default NotificationTable;
