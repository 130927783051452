import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { allCompanies } from "../../App";
import { defineStyles } from "../../styles/defineStyles";
import { findCompanyById, findUserById } from "../models/settings";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import CustomDialog from "../ui/CustomDialog";

const UserDetail: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 3,
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
  });
  const data = useParams();
  const userId = data.id;
  const company = findCompanyById(allCompanies, findUserById(userId).companyId);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const userDetailBreadcrumb: BreadCrumbsItem[] = [
    { name: "Klienti", path: "/clients" },
    { name: "Detail", path: "" },
  ];

  const onRemoveFuncitonHandler = () => {
    setShowDialog(true);
  };

  const closeHandler = () => {
    setShowDialog(false);
  };

  const removeUserHandler = () => {
    setShowDialog(false);
    // TODO remove user, maybe move of request and events to another user in company
  };

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={true}
        title="Uživatel detail"
        breadCrumb={userDetailBreadcrumb}
        addOrRemoveButtonCategory="user"
        pathToAdd=""
        isSearchFunctionActive={false}
        onRemoveFunction={onRemoveFuncitonHandler}
      />
      <Box sx={styles.main}>
        <Typography variant="h5">Základní informace</Typography>
        <TextField
          id="companyName"
          label="Název společnosti"
          value={company.name}
          inputProps={{ readOnly: true }}
          sx={styles.formItem}
        />
        <TextField
          id="userPhone"
          label="Telefon"
          value={findUserById(userId).phone}
          inputProps={{ readOnly: true }}
          sx={styles.formItem}
        />
        <TextField
          id="userEmail"
          label="Email"
          value={findUserById(userId).email}
          inputProps={{ readOnly: true }}
          sx={styles.formItem}
        />
      </Box>
      {showDialog && (
        <CustomDialog
          openDialog={showDialog}
          closed={closeHandler}
          title="Odstranit uživatele"
          subtitle="Odstraněním uživatele nedojde ke smazání žádostí aní událostí. Bude odstraněn pouze tento uživatel."
          commentLabel=""
          commentPlaceholder=""
          buttonText="Odstranit uživatele"
          onButtonClick={removeUserHandler}
        />
      )}
    </React.Fragment>
  );
};

export default UserDetail;
