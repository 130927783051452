import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import { Room } from "../models/settings";
import CalendarLink from "../ui/CalendarLink";
import Chips from "../ui/Chips";

const RoomCard: React.FC<{
  room: Room;
  isDetail: boolean;
  isSelected: (item: Room) => void;
}> = (props) => {
  const styles = defineStyles({
    card: {
      width: "calc((100vw - 367px)/4)",
      maxWidth: "400px",
      minWidth: "300px",
      maxHeight: "600px",
      mr: 2,
      mt: 2,
      display: "flex",
      flexDirection: "column",
    },
    cardContent: {
      flexGrow: "1",
    },
    roomStatus: {
      display: "flex",
      flexDirection: "row",
    },
    item: {
      pr: "8px",
    },
    title: {
      fontWeight: "500",
      fontSize: "20px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    description: {
      mb: 2,
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    cardActions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      p: 2,
    },
    actionButton: {
      ml: 1,
    },
  });

  let statusText = "";
  if (props.room.status) {
    statusText = "dostupna";
  } else {
    statusText = "nedostupna";
  }

  const detailUrl: string = "/rooms/" + props.room.id;
  const ctx = useContext(AuthContext);
  const selectedRoomHandler = () => {
    return props.isSelected(props.room);
  }

  return (
    <Card sx={styles.card}>
      <CardMedia component="img" height="200" image={props.room.imgUrl} />
      <CardContent sx={styles.cardContent}>
        <Box sx={styles.roomStatus}>
          <Typography
            color={props.room.status ? "primary" : "secondary"}
            sx={styles.item}
          >
            Status: {statusText}
          </Typography>
          <Typography color="primary" sx={styles.item}>
            •
          </Typography>
          <Typography
            color={props.room.freeCapacity != 0 ? "primary" : "secondary"}
            sx={styles.item}
          >
            Kapacita: {props.room.freeCapacity} lidi
          </Typography>
        </Box>
        <Typography sx={styles.title}>{props.room.title}</Typography>
        <Typography sx={styles.description}>
          {props.room.description}
        </Typography>
        <Chips chipArray={props.room.chips} />
      </CardContent>
      <Divider sx={{ mt: 2 }} />
      <CardActions sx={styles.cardActions}>
        <CalendarLink link={props.room.calendarUrl} />
        {props.isDetail && (
          <Link
            to={`/rooms/${props.room.id}`}
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained" sx={styles.actionButton}>
              Detail
            </Button>
          </Link>
        )}
        {!props.isDetail && (
          <Button variant="contained" sx={styles.actionButton} onClick={selectedRoomHandler}>
            Vyber
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default RoomCard;
