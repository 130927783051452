import { Box, Button, Stack, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import React, { useCallback, useState } from "react";
import {
  CheckboxElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useParams } from "react-router-dom";
import { allCompanies, allRoomsTemporary } from "../../App";
import { defineStyles } from "../../styles/defineStyles";
import {
  findCompanyById,
  findRequestById,
  findRoomByID,
  findUserById,
  RequestItem,
  useRequiredValidation,
} from "../models/settings";
import CalendarLink from "./CalendarLink";
import DatePickerComp from "./DatePickerComp";

export type DetailFormData = {
  roomName: { id: string; label: string };
  eventName: string;
  peopleCount: number;
  refreshmentCheckbox: boolean;
  eventDescripton: string;
  clientName: string;
  userEmail: string;
  userPhone: string;
  companyName: string;
  companyDescription: string;
};

const DetailForm: React.FC<{
  isSubmitAllowed: boolean,
  onSubmitAction: () => void
}> = (props) => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 888px)/2)",
      alignItem: "center",
      width: "600px",
    },
    description: {
      color: "rgba(0,0,0,0.6)",
    },
    date: {
      width: "100%",
      mt: 3,
      display: "flex",
      flexDirection: "row",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
    calendar: {
      ml: 2,
      mt: 2,
    },
    refreshmentCheckbox: {},
    /* button: {
          mt: 3,
          justifyContent: "right",
        }, */
    icon: {
      mr: 1,
      width: "16px",
      height: "18px",
    },
    button: {
      ml: 2,
      alignItems: "center",
    },
    buttonText: {
      fontSize: "13px",
    },
  });
  const required = useRequiredValidation();
  const urlData = useParams();
  const requestId: string = urlData.id;
  const [editedRequest, setEditedRequest] = useState<RequestItem>(
    findRequestById(requestId)
  );

  const [dateTimeInterval, setDateTimeInterval] = useState<Dayjs[] | null>([
    findRequestById(requestId).dateTimeFrom,
    findRequestById(requestId).dateTimeTo,
  ]);

  const dateTimeIntervalHandler = (interval: Dayjs[]) => {
    setDateTimeInterval(interval);
  };
  const submitHandler = useCallback((data: DetailFormData) => {
    //donothing
    props.onSubmitAction();
  },[props]);

  const allRooms: { id: string; label: string }[] = [];
  for (let i = 0; i < allRoomsTemporary.length; i++) {
    allRooms.push({
      id: allRoomsTemporary[i].id,
      label: allRoomsTemporary[i].title,
    });
  }

  return (
    <React.Fragment>
      <Typography variant="h5" sx={styles.description}>
        Žádost
      </Typography>
      <Box sx={styles.date}>
        <DatePickerComp
          dateFrom={dateTimeInterval[0]}
          dateTo={dateTimeInterval[1]}
          selectedDateInterval={dateTimeIntervalHandler}
        />
        <Box sx={styles.calendar}>
          <CalendarLink
            link={
              findRoomByID(allRoomsTemporary, editedRequest.room).calendarUrl
            }
          />
        </Box>
      </Box>
      <FormContainer
        defaultValues={{
          roomName: {
            id: editedRequest.room,
            label: findRoomByID(allRoomsTemporary, editedRequest.room).title,
          },
          eventName: editedRequest.name,
          peopleCount: editedRequest.peopleCount,
          refreshmentCheckbox: editedRequest.refreshment,
          eventDescripton: editedRequest.description,
          clientName: editedRequest.companyId,
          userEmail: findUserById(editedRequest.userId).email,
          userPhone: findUserById(editedRequest.userId).phone,
          companyName: findCompanyById(allCompanies, editedRequest.companyId)
            .name,
          companyDescription: findCompanyById(
            allCompanies,
            editedRequest.companyId
          ).description,
        }}
        onSuccess={submitHandler}
      >
        <SelectElement
          name="roomName"
          validation={required}
          label="Místnost"
          sx={styles.formItem}
          options={allRooms}
          inputProps={{ readOnly: !props.isSubmitAllowed }}
        />
        <TextFieldElement
          name="companyName"
          validation={required}
          label="Název klienta"
          sx={styles.formItem}
          inputProps={{ readOnly: true }}
        />
        <TextFieldElement
          name="companyDescription"
          validation={required}
          label="Popis klienta"
          sx={styles.formItem}
          inputProps={{ readOnly: true }}
        />
        <TextFieldElement
          name="userEmail"
          validation={required}
          label="Uživatelský účet"
          sx={styles.formItem}
          inputProps={{ readOnly: true }}
        />
        <TextFieldElement
          name="userPhone"
          validation={required}
          label="Telefon"
          sx={styles.formItem}
          inputProps={{ readOnly: true }}
        />
        <TextFieldElement
          name="eventName"
          validation={required}
          label="Název události"
          sx={styles.formItem}
          inputProps={{ readOnly: !props.isSubmitAllowed }}
        />
        <TextFieldElement
          name="peopleCount"
          validation={required}
          label="Počet lidí"
          sx={styles.formItem}
          helperText="Maximální přibližný počet očekávaných hostí události"
          inputProps={{ readOnly: !props.isSubmitAllowed }}
        />
        <Typography sx={styles.formItem}>Občerstvení z restaurace</Typography>
        <CheckboxElement
          name="refreshmentCheckbox"
          label="Mám zájem, aby me restaurace kontaktovala"
          helperText="Káva a voda jsou zdarma v rámci coworku"
          sx={styles.refreshmentCheckbox}
          inputProps={{ readOnly: !props.isSubmitAllowed }}
        />
        <TextFieldElement
          name="eventDescripton"
          label="Popis události (volitelné)"
          sx={styles.formItem}
          inputProps={{ readOnly: !props.isSubmitAllowed }}
        />
        {props.isSubmitAllowed && (
          <Stack direction="row" sx={{ mt: 3 }}>
            <div className="space"></div>
            <Button
              type="submit"
              variant="contained"
              sx={styles.button}
              color="primary"
            >
              <Typography sx={styles.buttonText}>Uložit změny</Typography>
            </Button>
          </Stack>
        )}
      </FormContainer>
    </React.Fragment>
  );
};

export default DetailForm;
