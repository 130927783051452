import { Box, Button, Stack } from "@mui/material";
import React from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import { allCompanies, allUsersTemp } from "../../App";
import { defineStyles } from "../../styles/defineStyles";
import {
  findCompanyById,
  User,
  useRequiredValidation,
} from "../models/settings";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";

const NewUser: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 788px)/2)",
      alignItem: "center",
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
  });

  const newUserBreadcrumb: BreadCrumbsItem[] = [
    { name: "Klienti", path: "/clients" },
    { name: "Nový uživatel", path: "" },
  ];

  const required = useRequiredValidation();
  const navigate = useNavigate();

  const addNewUserHandler = (data: { company: string; userEmail: string }) => {
    //add new user
    let isAdmin = false;
    if (findCompanyById(allCompanies, data.company).name === "Admin")
      isAdmin = true;
    const newUser: User = new User(
      isAdmin,
      "",
      "",
      data.userEmail,
      "",
      data.company,
      ""
    );
    allUsersTemp.push(newUser);
    const path = "/clients/" + data.company;
    navigate(path);
  };

  const allCompaniesOptions: { id: string; label: string }[] | null = [];
  for (let i = 0; i < allCompanies.length; i++) {
    allCompaniesOptions.push({
      id: allCompanies[i].id,
      label: allCompanies[i].name,
    });
  }

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={false}
        title="Nový uživatel"
        breadCrumb={newUserBreadcrumb}
        addOrRemoveButtonCategory="user"
        pathToAdd=""
        isSearchFunctionActive={false}
      />
      <Box sx={styles.main}>
        <FormContainer defaultValues={{}} onSuccess={addNewUserHandler}>
          <SelectElement
            id="company"
            name="company"
            label="Klient"
            validation={required}
            options={allCompaniesOptions}
            sx={styles.formItem}
          />
          <TextFieldElement
            id="userEmail"
            name="userEmail"
            label="Email"
            validation={required}
            sx={styles.formItem}
          />
          <Stack direction="row" sx={styles.formItem}>
            <div className="space"></div>
            <Button type="submit" variant="contained">
              Vytvořit nový účet
            </Button>
          </Stack>
        </FormContainer>
      </Box>
    </React.Fragment>
  );
};

export default NewUser;
