import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import {
  findCompanyByName,
  findRoomByID,
  NotificationItem,
  RequestItem,
  Room,
  useRequiredValidation,
} from "../models/settings";
import RoomTabs from "../rooms/RoomTabs";
import DatePickerComp from "../ui/DatePickerComp";
import {
  CheckboxElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import {
  admin,
  allCompanies,
  allNotificationsTemp,
  allRequestsTemp,
  allRoomsTemporary,
  allUsersTemp,
  klient,
} from "../../App";
import { Link } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

type NewRequestData = {
  eventName: string;
  peopleCount: number;
  eventDescripton: string;
  refreshmentCheckbox: boolean;
  companyName: string;
  userEmail: string;
};

const NewRequest: React.FC = () => {
  const styles = defineStyles({
    stepperBox: {
      mt: 3,
      width: "100%",
      height: "62px",
      mb: 3,
      position: "relative",
    },
    stepper: {
      position: "absolute",
      left: "50%",
      top: "50%",
      width: "396px",
      height: "62px",
      transform: "translate(-50%, -50%)",
    },
    description: {
      color: "rgba(0,0,0,0.6)",
    },
    icon: {
      color: "rgba(0,0,0,0.6)",
      ml: 1,
      mr: 2,
      width: "16px",
      height: "16px",
    },
    datePicker: {
      mt: 3,
      mb: 11,
      p: 2,
      background: "rgba(25, 118, 210, 0.04)",
      width: "500px",
      height: "45px",
    },
    blockPartTwo: {
      mt: 9,
      ml: "calc((100vw - 788px)/2)",
      alignItem: "center",
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
    refreshmentCheckbox: {},
    button: {
      mt: 3,
      justifyContent: "right",
    },
    modalTextBold: {
      fontWeight: "bold",
    },
    modalRefreshment: {
      mt: 3,
    },
  });

  const ctx = useContext(AuthContext);
  const newRequestBreadCrumb: BreadCrumbsItem[] = [
    { name: "Žádosti", path: "/requests" },
    { name: "Nová", path: "" },
  ];

  const [activeStep, setActiveStep] = useState<number>(0);
  const steps: string[] = ["Základní info", "Formulář žádosti", "Potvrzení"];
  const [selectedRoom, setSelectedRoom] = useState<Room | null>(null);
  const [newRequest, setNewRequest] = useState<RequestItem | null>(null);
  const required = useRequiredValidation();

  const [dateTimeInterval, setDateTimeInterval] = useState<Dayjs[] | null>(
    null
  );

  const onRoomSelectedHandler = useCallback(
    (room: Room) => {
      let company: string = null;
      let userId: string = undefined;
      if (ctx.isLoggedAdmin) {
        company = undefined;
        userId = admin.id;
      } else {
        company = klient.companyId;
        userId = klient.id;
      }
      setActiveStep((activestep) => activestep + 1);
      setSelectedRoom(room);
      setNewRequest(() => {
        const newRequest: RequestItem = new RequestItem(
          "",
          "",
          room.id,
          company,
          undefined,
          false,
          false,
          false,
          dateTimeInterval[0],
          dateTimeInterval[1],
          userId,
          ""
        );
        return newRequest;
      });
    },
    [ctx, klient, dateTimeInterval]
  );

  const submitHandler = useCallback(
    (data: NewRequestData) => {
      setActiveStep((activestep) => activestep + 1);
      setOpen(true);
      setNewRequest((prevData) => {
        let description = "";
        let company = "";
        let userEmail = "";
        if (data.eventDescripton) description = data.eventDescripton;
        if (data.companyName && data.userEmail && ctx.isLoggedAdmin) {
          company = data.companyName;
          userEmail = data.userEmail;
          return {
            ...prevData,
            name: data.eventName,
            description: description,
            peopleCount: data.peopleCount,
            refreshment: data.refreshmentCheckbox,
            dateTimeFrom: dateTimeInterval[0],
            dateTimeTo: dateTimeInterval[1],
            companyId: company,
            userId: userEmail,
            isAccepted: true,
            isDecided: true,
          };
        } else {
          return {
            ...prevData,
            name: data.eventName,
            description: description,
            peopleCount: data.peopleCount,
            refreshment: data.refreshmentCheckbox,
            dateTimeFrom: dateTimeInterval[0],
            dateTimeTo: dateTimeInterval[1],
          };
        }
      });
    },
    [newRequest, activeStep]
  );

  const [open, setOpen] = useState<boolean>(true);
  const handleClose = () => {
    setActiveStep((activestep) => activestep - 1);
    setOpen(false);
  };

  const addNewRequestHandler = () => {
    allRequestsTemp.push(newRequest);
    allNotificationsTemp.push(new NotificationItem(newRequest.id, dayjs(), false));
  };

  const dateTimeIntervalHandler = (interval: Dayjs[]) => {
    setDateTimeInterval(interval);
  };

  const allClients: { id: string; label: string }[] | null = [];
  for (let i = 0; i < allCompanies.length; i++) {
    allClients.push({ id: allCompanies[i].id, label: allCompanies[i].name });
  }

  const allUsers: { id: string; label: string }[] | null = [];
  for (let i = 0; i < allUsersTemp.length; i++) {
    allUsers.push({ id: allUsersTemp[i].id, label: allUsersTemp[i].email });
  }

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={true}
        title="Nová žádost"
        breadCrumb={newRequestBreadCrumb}
        addOrRemoveButtonCategory="request"
        pathToAdd={""}
        isSearchFunctionActive={false}
      />
      <Box sx={styles.stepperBox}>
        <Stepper activeStep={activeStep} alternativeLabel sx={styles.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep == 0 && (
        <Box>
          <Typography variant="h5">1. Termín</Typography>
          <Typography variant="body2" sx={styles.description}>
            Pro vytvoření nové žádosti, nejdříve vyberte termín rezervace.
          </Typography>
          <Box sx={styles.datePicker}>
            <DatePickerComp
              dateFrom={dayjs()}
              dateTo={dayjs().add(2, "hours")}
              selectedDateInterval={dateTimeIntervalHandler}
            />
          </Box>
          <Box>
            <Typography variant="h5">2. Výběr místnosti</Typography>
            <RoomTabs isDetail={false} isSelected={onRoomSelectedHandler} />
          </Box>
        </Box>
      )}
      {activeStep == 1 && (
        <Box sx={styles.blockPartTwo}>
          <Typography variant="body2" sx={styles.description}>
            Termín
          </Typography>
          <DatePickerComp
            dateFrom={dateTimeInterval[0]}
            dateTo={dateTimeInterval[1]}
            selectedDateInterval={dateTimeIntervalHandler}
          />
          <TextField
            label="Místnost"
            id="roomName"
            value={selectedRoom.title}
            inputProps={{ readOnly: true }}
            sx={styles.formItem}
          />
          <Divider sx={styles.formItem} />
          <FormContainer
            defaultValues={{
              eventName: newRequest.name,
              peopleCount: newRequest.peopleCount,
              refreshmentCheckbox: newRequest.refreshment,
              eventDescripton: newRequest.description,
              companyName: newRequest.companyId,
            }}
            onSuccess={submitHandler}
          >
            {ctx.isLoggedAdmin && (
              <React.Fragment>
                <SelectElement
                  name="companyName"
                  validation={required}
                  label="Název klienta"
                  sx={styles.formItem}
                  options={allClients}
                />
                <SelectElement
                  name="userEmail"
                  validation={required}
                  label="Uživatelský účet"
                  sx={styles.formItem}
                  options={allUsers}
                />
              </React.Fragment>
            )}
            <TextFieldElement
              name="eventName"
              validation={required}
              label="Název události"
              sx={styles.formItem}
            />
            <TextFieldElement
              name="peopleCount"
              validation={required}
              label="Počet lidí"
              sx={styles.formItem}
              helperText="Maximální přibližný počet očekávaných hostí události"
            />
            <Typography sx={styles.formItem}>
              Občerstvení z restaurace
            </Typography>
            <CheckboxElement
              name="refreshmentCheckbox"
              label="Mám zájem, aby me restaurace kontaktovala"
              helperText="Káva a voda jsou zdarma v rámci coworku"
              sx={styles.refreshmentCheckbox}
            />
            <TextFieldElement
              name="eventDescripton"
              label="Popis události (volitelné)"
              sx={styles.formItem}
            />
            <Stack direction="row">
              <div className="space"></div>
              <Button type="submit" variant="contained" sx={styles.button}>
                Pokračovat
              </Button>
            </Stack>
          </FormContainer>
        </Box>
      )}
      {activeStep == 2 && (
        <Dialog open={open} onClose={handleClose}>
          {!ctx.isLoggedAdmin && (
            <DialogTitle>Závazné potvrzení žádosti</DialogTitle>
          )}
          {ctx.isLoggedAdmin && (
            <DialogTitle>Závazné potvrzení rezervace</DialogTitle>
          )}
          <DialogContent>
            {!ctx.isLoggedAdmin && (
              <Typography component="span">
                Závazně potvrzuji žádost{" "}
              </Typography>
            )}
            {ctx.isLoggedAdmin && (
              <Typography component="span">
                Závazně potvrzuji rezervaci události{" "}
              </Typography>
            )}
            <Typography component="span" sx={styles.modalTextBold}>
              {newRequest.name}
            </Typography>
            <Typography component="span"> v místnosti </Typography>
            <Typography component="span" sx={styles.modalTextBold}>
              {findRoomByID(allRoomsTemporary, newRequest.room).title}
            </Typography>
            <Typography component="span"> v termínu </Typography>
            <Typography component="span" sx={styles.modalTextBold}>
              {dayjs(newRequest.dateTimeFrom).format("DD.MM.YYYY HH:mm")} -{" "}
              {dayjs(newRequest.dateTimeTo).format("DD.MM.YYYY HH:mm")}.
            </Typography>
            {newRequest.refreshment && !ctx.isLoggedAdmin && (
              <Box sx={styles.modalRefreshment}>
                <Typography component="span">
                  Potvrzuji, že mám zájem, aby mě{" "}
                </Typography>
                <Typography component="span" sx={styles.modalTextBold}>
                  kontaktovala restaurace{" "}
                </Typography>
                <Typography component="span">ohledně občerstvení.</Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Stack direction="row">
              <div className="space"></div>
              <Button onClick={handleClose} variant="text" color="error">
                Zpět
              </Button>
              <Link to="/requests" style={{ textDecoration: "none" }}>
                <Button variant="text" onClick={addNewRequestHandler}>
                  Potvrdit
                </Button>
              </Link>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default NewRequest;
