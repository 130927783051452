import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
  } from "@mui/material";
  import React, { useContext } from "react";
  import { allCompanies } from "../../App";
  import { defineStyles } from "../../styles/defineStyles";
  import AuthContext from "../contexts/AuthContext";
import ClientsTableRow from "./ClientsTableRow";
  
  const ClientsTable: React.FC = () => {
    const styles = defineStyles({
      table: {
        mt: 3,
      },
      head: {
        fontWeight: "bold",
        width: "100%"
      },
      headName: {
        fontWeight: "bold",
        width: "40%"
      },
      headDescription: {
        fontWeight: "bold",
        width: "40%"
      },
      headItem: {
        fontWeight: "bold",
        width: "10%"
      }
    });
  
    const changePageHandler = () => {
      //donothing
    };
    const changeRowsPerPageHandler = () => {
      //donothing
    };
    const ctx = useContext(AuthContext);
  
    const rateRequestHandler = (value: boolean) => {
      //donothing
    };
    return (
      <TableContainer component={Box} id="tableContainer">
        <Table sx={styles.table}>
          <TableHead>
            <TableRow key="headerRow">
              <TableCell sx={styles.headName}>Název společnosti</TableCell>
              <TableCell sx={styles.headDescription}>Popis</TableCell>
              <TableCell sx={styles.headItem}>Počet událostí</TableCell>
              <TableCell sx={styles.headItem}>Počet žádostí</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allCompanies.map((company) => (
                <ClientsTableRow key={company.id} company={company} link="/clients/" />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={1}
          rowsPerPage={5}
          page={0}
          onPageChange={changePageHandler}
          onRowsPerPageChange={changeRowsPerPageHandler}
        />
      </TableContainer>
    );
  };
  
  export default ClientsTable;