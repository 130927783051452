import {AlertColor} from '@mui/material/Alert/Alert';
import React, {useCallback, useMemo, useReducer} from 'react';
import {v4 as uuid} from 'uuid';

type Context = {
  alerts: Alerts;
  showSuccess: (message: string, description: string, timeout?: number) => void;
  showError: (message: string, description?: string, timeout?: number) => void;
};

const AlertContext = React.createContext<Context>(null);
AlertContext.displayName = 'AlertContext';

export type Alert = {
  id: string;
  message: string;
  description?: string;
  type: AlertColor;
};

type Alerts = Alert[];

enum ReducerActionType {
  Add,
  Remove,
}

type ReducerAction = {
  type: ReducerActionType;
  alert: Alert;
};

const reducer = (state: Alerts, action: ReducerAction): Alerts => {
  switch (action.type) {
    case ReducerActionType.Add:
      return [...state, action.alert];
    case ReducerActionType.Remove:
      return state.filter((alert) => alert.id !== action.alert.id);
  }

  return state;
};

type AlertProviderProps = {
  children: React.ReactNode;
};

const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alerts, dispatch] = useReducer(reducer, []);

  const showAlert = useCallback(
    (
      message: string,
      description?: string,
      timeout?: number,
      type?: AlertColor
    ) => {
      const alert: Alert = {
        id: uuid(),
        message,
        description,
        type,
      };

      dispatch({ type: ReducerActionType.Add, alert });
      setTimeout(
        () => dispatch({ type: ReducerActionType.Remove, alert }),
        timeout ?? 3000
      );
    },
    []
  );

  const showSuccess = useCallback(
    (message: string, description: string, timeout = 5000) =>
      showAlert(message, description, timeout, 'success'),
    [showAlert]
  );

  const showError = useCallback(
    (message: string, description: string, timeout = 5000) =>
      showAlert(message, description, timeout, 'error'),
    [showAlert]
  );

  const ctx: Context = useMemo(
    () => ({
      alerts: alerts,
      showSuccess,
      showError,
    }),
    [alerts, showError, showSuccess]
  );

  return <AlertContext.Provider value={ctx}>{children}</AlertContext.Provider>;
};

export { AlertProvider };
export default AlertContext;
