import { CalendarToday, Inbox, Person } from "@mui/icons-material";
import { Box, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { allCompanies } from "../../App";
import { defineStyles } from "../../styles/defineStyles";
import EventTable from "../events/EventTable";
import { findAllEventsForCompany, findCompanyById, findRequestsForCompany } from "../models/settings";
import CustomDialog from "../ui/CustomDialog";
import RequestTable from "../requests/RequestTable";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import UserPage from "../User/UserPage";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`ab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const ClientDetail: React.FC = () => {
  const styles = defineStyles({
    basicInfo: {
      mt: 3,
      mb: 9,
      width: "500px",
    },
    infoItem: {
      mt: 3,
      width: "100%",
    },
    mainTabs: {
      width: "100%",
    },
    tabs: {},
    icon: {
      height: "24px",
      width: "24px",
      m: 0,
    },
  });

  const [value, setValue] = React.useState(0);
  const urlData = useParams();
  const companyId = urlData.id;
  const currentCompany = findCompanyById(allCompanies, companyId);
  const appBarTitle = "Klient: " + currentCompany.name;
  const companyDetailBreadCrumb: BreadCrumbsItem[] = [
    { name: "Klienti", path: "/clients/" },
    { name: "Detail klienta", path: "" },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [showDialog, setShowDialog]=useState<boolean>(false);

  const onRemoveFuncitonHandler = () => {
    //TODO nothing happens
    setShowDialog(true);
  };

  const closeHandler = () => {
    setShowDialog(false);
  }

  const onRemoveCompanyHandler = () => {
    setShowDialog(false);
    // TODO remove company and request+events
  }

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={true}
        title={appBarTitle}
        breadCrumb={companyDetailBreadCrumb}
        addOrRemoveButtonCategory="client"
        pathToAdd=""
        isSearchFunctionActive={false}
        onRemoveFunction={onRemoveFuncitonHandler}
      />
      <Box sx={styles.basicInfo}>
        <Typography variant="h5">Základní informace</Typography>
        <TextField
          variant="outlined"
          label="Název společnosti"
          id="companyName"
          value={currentCompany.name}
          inputProps={{ readOnly: true }}
          sx={styles.infoItem}
        />
        <TextField
          variant="outlined"
          label="Popis společnosti"
          id="companyDescription"
          value={currentCompany.description}
          inputProps={{ readOnly: true }}
          sx={styles.infoItem}
          multiline
          rows={2}
        />
      </Box>
      <Box sx={styles.mainTabs}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            sx={styles.tabs}
          >
            <Tab
              label="Účty"
              icon={<Person sx={styles.icon} />}
              iconPosition="start"
              {...a11yProps(0)}
            />
            <Tab
              label="Žádosti"
              icon={<CalendarToday sx={styles.icon} />}
              iconPosition="start"
              {...a11yProps(1)}
            />
            <Tab
              label="Události"
              icon={<Inbox sx={styles.icon} />}
              iconPosition="start"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <UserPage />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RequestTable requests={findRequestsForCompany(currentCompany.id)}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EventTable events={findAllEventsForCompany(currentCompany.id)}/>
        </TabPanel>
      </Box>
      {showDialog && (
        <CustomDialog 
        openDialog={showDialog}
        closed={closeHandler}
        title="Odstranit klienta"
        subtitle="Tímto potvrzujete odstranění klienta se systému. Poodstranění klienta, se automaticky odstraní i všechny uživatelksé účty a potvzené události z kalendáře."
        commentLabel=""
        commentPlaceholder=""
        buttonText="Odstranit klienta"
        onButtonClick={onRemoveCompanyHandler}
        />
      )}
    </React.Fragment>
  );
};

export default ClientDetail;
