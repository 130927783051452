import { Box } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { defineStyle } from '../styles/defineStyles';
import  Home  from './Home';
import { RoomsPage } from './rooms/RoomsPage';
import { AppSettings } from './settings/AppSettings';
import RoomDetail from './rooms/RoomDetail';
import RequestPage  from './requests/RequestPage';
import NewRequest from './requests/NewRequest';
import EventPage from './events/EventPage';
import EditRequest from './requests/EditRequest';
import EditEvent from './events/EditEvent';
import ClientsPage from './clients/ClientsPage';
import ClientDetail from './clients/ClientDetail';
import NewUser from './User/NewUser';
import UserDetail from './User/UserDetail';
import NewClient from './clients/NewClient';
import NewAdmin from './settings/NewAdmin';
import NotificationPage from './notification/NotificationPage';
const style = defineStyle({
  width: 'auto',
  pl: 18, //144px
  pr: 18, //144px
  pt: 13, //104px
  pb: 7 //56px
});

export const Content: React.FC = () => {

  return (
    <Box sx={style}>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/requests" element={<RequestPage />} />
        <Route path="/requests/new" element={<NewRequest />} />
        <Route path="/requests/:id" element={<EditRequest />} />
        <Route path="/events" element={<EventPage />} />
        <Route path="/events/:id" element={<EditEvent />} />
        <Route path="/clients" element={<ClientsPage />} />
        <Route path="/clients/new" element={<NewClient />} />
        <Route path="/clients/:id" element={<ClientDetail />} />
        <Route path="/clients/user/:id" element={<UserDetail />} />
        <Route path="/clients/user/new" element={<NewUser />} />
        <Route path="/rooms" element={<RoomsPage />} />
        <Route path="/rooms/:id" element={<RoomDetail />}/>
        <Route path="/settings" element={<AppSettings />} />
        <Route path="/settings/new" element={<NewAdmin />} />
        <Route path="/notifications" element={<NotificationPage />} />
      </Routes>
    </Box>
  );
};
