import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import CustomDialog from "../ui/CustomDialog";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import DetailForm from "../ui/DetailForm";

const EditEvent: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 888px)/2)",
      alignItem: "center",
      width: "600px",
    },
    icon: {
      mr: 1,
      width: "16px",
      height: "18px",
    },
    button: {
      ml: 2,
      alignItems: "center",
    },
    buttonText: {
      fontSize: "13px",
    },
  });
  const ctx = useContext(AuthContext);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const path = "/events/";

  const editEventBreadcrumb: BreadCrumbsItem[] = [
    { name: "Události", path: "/events" },
    { name: "Detail události", path: "" },
  ];

  const onRemoveFunctionHandler = () => {
    setShowDialog(true);
  }
  const closeDialogHandler = () => {
    setShowDialog(false);
  };

  const onEventRemoveHandler = (comment: string) => {
    // TODO provede odstraneni udalosti
    setShowDialog(false);
    navigate(path);
  }

  const onSubmitActionHandler = () => {
    // po ulozeni dojde k presmerovani na events
    navigate(path);
  }

  return (
    <React.Fragment>
      {ctx.isLoggedAdmin && (
        <React.Fragment>
          <AppBarCustom
            isDetail={true}
            title="Detail události"
            breadCrumb={editEventBreadcrumb}
            addOrRemoveButtonCategory="event"
            pathToAdd={""}
            isSearchFunctionActive={false}
            onRemoveFunction={onRemoveFunctionHandler}
          />
          <Box sx={styles.main}>
            <DetailForm isSubmitAllowed={true} onSubmitAction={onSubmitActionHandler}/>
            
          </Box>
          {showDialog && (
            <CustomDialog 
            openDialog={showDialog}
            title="Odstranění události"
            subtitle="Předtím než definitivně událost odstraníte, udejte prosím důvod. Zvýšíte tak efektivitu komunikace s klientem."
            commentLabel="Důvod odstranění události"
            commentPlaceholder=""
            closed={closeDialogHandler}
            buttonText="Odstranit událost"
            onButtonClick={onEventRemoveHandler}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default EditEvent;
