import { CancelRounded } from "@mui/icons-material";
import { Button, TableCell, TableRow } from "@mui/material";
import React from "react";
import { allUsersTemp } from "../../App";
import { defineStyles } from "../../styles/defineStyles";
import { findAllAdmins, User } from "../models/settings";

const AdminSettingsTableRow: React.FC<{
  admin: User;
}> = (props) => {
  const styles = defineStyles({
    iconButton: {
      mr: 1,
    },
  });

  const adminCount = findAllAdmins().length;

  const deleteAdminHandler = () => {
    /* for (let i = 0; i < allUsersTemp.length; i++) {
      if (props.admin.id === allUsersTemp[i].id) {
        return allUsersTemp.splice(i, 1);
      }
    } */
    console.log("Odebrat admina: " + props.admin.email);
  };

  return (
    <TableRow>
      <TableCell>{props.admin.email}</TableCell>
      <TableCell>
        {adminCount > 1 && (
          <Button
            color="inherit"
            variant="contained"
            onClick={deleteAdminHandler}
          >
            <CancelRounded sx={styles.iconButton} />
            Odebrat
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default AdminSettingsTableRow;
