import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import RoomList from "./RoomList";
import { Room } from "../models/settings";
import { allRoomsTemporary } from "../../App";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`ab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const RoomTabs: React.FC<{
  isDetail: boolean,
  isSelected: (item: Room) => void
}> = (props) => {
  const [value, setValue] = React.useState(0);

  const findAvailableRooms = (rooms: Room[]) => {
    const available: Room[] = [];
    for (let i=0; i<rooms.length;i++) {
      if (rooms[i].status) available.push(rooms[i]);
    }
    return available;
  }
  const findNotAvailableRooms = (rooms: Room[]) => {
    const notAvailable: Room[] = [];
    for (let i=0; i<rooms.length;i++) {
      if(!rooms[i].status) notAvailable.push(rooms[i]);
    } 
    return notAvailable;
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label="Všechny" {...a11yProps(0)} />
          <Tab label="Dostupné" {...a11yProps(1)} />
          <Tab label="Nedostupné" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <RoomList allRooms={allRoomsTemporary} isDetail={props.isDetail} isSelected={props.isSelected}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RoomList allRooms={findAvailableRooms(allRoomsTemporary)} isDetail={props.isDetail} isSelected={props.isSelected}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <RoomList allRooms={findNotAvailableRooms(allRoomsTemporary)} isDetail={props.isDetail} isSelected={props.isSelected}/>
      </TabPanel>
    </Box>
  );
};

export default RoomTabs;
