import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { allRequestsTemp } from "../../App";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import { findAllEventsTemp, RequestItem } from "../models/settings";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import Unavailable from "../ui/Unavailable";
import EmptyEventContent from "./EpmtyEventContent";
import EventTable from "./EventTable";

const EventPage: React.FC = () => {
  const styles = defineStyles({
    tabs: {
      marginTop: "20px",
    },
  });

  const roomBreadCrumb: BreadCrumbsItem[] = [
    { name: "Události", path: "/events" },
  ];
  const ctx = useContext(AuthContext);

  const [allRequests, setAllRequests] = useState<RequestItem[]>([]);

  useEffect(() => {
    setAllRequests(allRequestsTemp);
  }, []);


  return (
    <React.Fragment>
      {ctx.isLoggedAdmin && (
        <React.Fragment>
          <AppBarCustom
            isDetail={false}
            title="Události"
            breadCrumb={roomBreadCrumb}
            addOrRemoveButtonCategory="event"
            pathToAdd=""
            isSearchFunctionActive={false}
          />
          <Box sx={styles.tabs}>
            {allRequests.length == 0 && <EmptyEventContent />}
            {allRequests.length > 0 && <EventTable events={findAllEventsTemp()} />}
          </Box>
        </React.Fragment>
      )}
      {!ctx.isLoggedAdmin && <Unavailable />}
    </React.Fragment>
  );
};

export default EventPage;
